import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { updateParticipant, getUIContent, vendorRedirectUpdate, trackParticipants, noSurveyForRouter,
        updateClientAppRedirectUpdate,
        getRedirectUrlForUserSurveyAttempt } from './actions/index';
import { getDefaultContent, getRouterYesNoContent, getWaitingContent} from './utils/utils'; 
import { requestData } from './helpers/requestData';
import { getUserSurveyList } from "./elements/userSurveyList";
import './style.css';
import './main.css';
import { FormattedMessage } from 'react-intl';
import appConfig from '../config/apiConfig';

const QuotaFail = (props) => {

    let request = requestData(window);
    const [sid] = useState("sid")
    const [userId, setUserId] = useState(request.searchParams)
    const [redirectedUrl, setRedirectedUrl] = useState(request.origionNotHttp1)
    const [trackUrl, setTrackUrl] = useState(request.trackUrl)
    const [participant, setParticipant] = useState("")
    const [participantUpdateData, setParticipantUpdateData] = useState({})
    const [isUpdateParticipantCalled, setIsUpdateParticipantCalled] = useState(false)
    const [isVendorRedirectCalled, setIsVendorRedirectCalled] = useState(false)
    const [isClientAppRedirectCalled, setIsClientAppRedirectCalled] = useState(false)
    const [isWaiting, setIsWaiting] = useState(false)
    const [bodyMessage, setBodyMessage] = useState("")
    const [content, setContent] = useState(getDefaultContent(bodyMessage))
    const [headerMessage, setHeaderMessage] = useState("")

    useEffect(() => {
        if (userId !== "") {
            updateParticipant(userId)
        }
        props.trackParticipants(trackUrl);
    }, [userId]);


    const onYesClickedForNewSurvey = () => {
      // Redirect to zampparticipant to get new participant
      let url =  appConfig.participantUrl+"?"+participant.routerParticipantUrl;
      window.location.href = url;         
    }

    const onMultipleAttemptClick = (item, clientType, isFirstAttempt, parentPID) => {
        let dataToPass = {};

        if (clientType == 11 || clientType == 2) {
          dataToPass.clientProjectId = item.ProjectId;
          dataToPass.LOI = item.SurveyLength;
          dataToPass.IR = item.Ir;
          dataToPass.CPI = item.MemberReward;
          dataToPass.PID = parentPID;
          dataToPass.apiType = clientType;
          dataToPass.isFirstAttempt = isFirstAttempt;
          dataToPass.surveyUrl = item.SurveyUrl;
          dataToPass.landingUrl = redirectedUrl;
        }
        setIsWaiting(true);
        props.getRedirectUrlForUserSurveyAttempt(dataToPass);      
      }

    const onNoSurveryRequired = () => {
       // redirect back to vendor with all status
        setIsWaiting(true);
       props.noSurveyForRouter(participant.originPID);
    }

    const updateClientAppRedirect = (url) => {
        setTimeout(function () { 
            window.location.href = url;         
        }, 1000);
        if (isVendorRedirectCalled === false) {
            setIsVendorRedirectCalled(true);
            props.vendorRedirectUpdate(participantUpdateData, url);
        }
    }

    const updateFirstPartyUrl = (data, url) => {
        setTimeout(function () { 
            window.location.href = url;         
        }, 1000);
        if (isClientAppRedirectCalled === false) {
            setIsClientAppRedirectCalled(true);
            props.updateClientAppRedirectUpdate(data, url);
        }
    }

    const updateParticipant = () => {
        let participantData = {
            sid: sid,
            status: 2,
            uid: userId,
            redirectedUrl: redirectedUrl,
            checkLoi: false,
        };
        setParticipantUpdateData(participantData);
        //props.getUIContent(participantData);
        props.updateParticipant(participantData);
    }

    useEffect(() => {
        setContent(getDefaultContent(headerMessage, bodyMessage));

        if (props.uiContent !== undefined && props.uiContent.success) {
            let headerMsg = props.uiContent.headerMessage;
            let message = props.uiContent.message;
            if (isUpdateParticipantCalled === false) {
                setIsUpdateParticipantCalled(true);
                setBodyMessage(message);
                setHeaderMessage(headerMsg);
            }
        }

        if( props.participantResponse &&
            props.participantResponse.hasOwnProperty("isMultipleWindow") &&
            props.participantResponse.isMultipleWindow === true){
                setIsWaiting(false);
            }

        if(isWaiting){
            setContent(getWaitingContent());
        } else {
            if (props.participantResponse !== undefined && props.participantResponse != null && props.participantResponse.success) {
                setParticipant(props.participantResponse)
    
                if( props.participantResponse &&
                    props.participantResponse.hasOwnProperty("isMultipleWindow") &&
                    props.participantResponse.isMultipleWindow === true){
                        setContent(getUserSurveyList(props.participantResponse.userSurveys,
                            props.participantResponse.apiType,
                            props.participantResponse.isFirstAttempt,
                            onMultipleAttemptClick,
                            props.participantResponse.PID));
                } else if(props.participantResponse.holdRedirect){
                    setContent(getDefaultContent(headerMessage, bodyMessage));
                } else if(props.participantResponse.isRouterChainEnabled){
                    setContent(getRouterYesNoContent(props, onYesClickedForNewSurvey, onNoSurveryRequired))
                } else if (props.participantResponse.isRedirect) {
                    if(props.participantResponse.hasOwnProperty("isFirstPartyRedirect")
                        && props.participantResponse.isFirstPartyRedirect == true){
                            updateFirstPartyUrl(props.participantResponse, props.participantResponse.redirectUrl);
                        } else {
                            updateClientAppRedirect(props.participantResponse.redirectUrl);
                        }                   
                }
            } else if (props.participantResponse !== undefined && props.participantResponse != null && props.participantResponse.success === false) {
                setBodyMessage(props.participantResponse.message)
            }
        }




        if (props.noSurveyResponse !== undefined && props.noSurveyResponse != null && props.noSurveyResponse.success) {
            if (props.noSurveyResponse.isRedirect) {
                updateClientAppRedirect(props.noSurveyResponse.redirectUrl);
            }
        }

        


    }, [participant, isWaiting, headerMessage, bodyMessage, props.participantResponse, props.participantResponse, props.surveyResponse, props.uiContent, props.vendorRedirectResult, props.noSurveyResponse])
    return (
        <div className="animate-bottom">{content}</div>
    )
}
const mapStateToProps = (state) => {
    return {
        participantResponse: state.quotaFail.participantResponse,
        uiContent: state.quotaFail.uiContent,
        noSurveyResponse : state.quotaFail.noSurveyResponse
    }
}
export default connect(mapStateToProps, { updateParticipant, getUIContent, vendorRedirectUpdate, trackParticipants, noSurveyForRouter , updateClientAppRedirectUpdate, getRedirectUrlForUserSurveyAttempt  })(QuotaFail);