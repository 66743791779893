import {updateParticipantApi, noSurveyRequiredByUserApi, getRedirectAppMessageApi, 
    vendorRedirectUpdateApi,trackParticipantsApi,
     noSurveyForRouterApi,
     clientUpdateRedirectUpdateApi,
     getRedirectUrlForUserAttemptApi} from '../services/index';
// set Update Participant Response
export const setUpdateParticipantResponse =(response) => {
    return {
        type : "SET_UPDATE_PARTICIPANT_RESPONSE",
        response
    }
}
// set No Survey Require Response
export const setSurveyRequiredByUserResponse =(response) => {
    return {
        type : "SET_NO_SURVEY_REQUIRE",
        response
    }
}

export const setRedirectAppMessage = (uiContent) => {
	return {
		type: "SET_UI_CONTENT",
		uiContent
	}
}

export const setVendorRedirectUpdate = (vendorRedirect) => {
	return {
		type: "SET_VENDOR_REDIRECT_UPDATE",
		vendorRedirect
	}
}
// update Participant
export const updateParticipant = (data) => {
    return dispatch => {
        updateParticipantApi(data).then((result) => {
            dispatch(setUpdateParticipantResponse(result));
        });
    }
}

export const getRedirectUrlForUserSurveyAttempt = (data) => {
    return dispatch => {
		getRedirectUrlForUserAttemptApi(data).then((result) => {
            dispatch(setUpdateParticipantResponse(result));
		})
    }
}

export const getUIContent = (data) => {
	return dispatch => {

		getRedirectAppMessageApi(data).then((result) => {
			dispatch(setRedirectAppMessage(result));
		});
	}
}

export const vendorRedirectUpdate = (data, redirectVendor) => {
	return dispatch => {

		vendorRedirectUpdateApi(data, redirectVendor).then((result) => {
			dispatch(setVendorRedirectUpdate(result));
		});
	}
}


// noSurveyRequiredByUser
export const noSurveyRequiredByUser = (data) => {
    return dispatch => {
        noSurveyRequiredByUserApi(data).then((result) => {
            dispatch(setSurveyRequiredByUserResponse(result));
        });
    }
}

export const trackParticipants = (url) => {
    return dispatch => {
        trackParticipantsApi(url).then((result) => {
            dispatch(trackParticipantsResponse(result));
        });
    }
}

export const trackParticipantsResponse = (response) => {
    return {
        type: "SET_TRACKING_RESPONSE",
        response
    }
}

export const updateClientAppRedirectUpdate = (data, url) => {
    return dispatch => {
        clientUpdateRedirectUpdateApi(data, url).then((result) => {});
    }
}

export const noSurveyForRouter = (originPID) => {
    return dispatch => {
        noSurveyForRouterApi(originPID).then((result) => {
            dispatch(noSurveyForRouterResponse(result));
        });
    }
}

export const noSurveyForRouterResponse = (response) => {
    return {
        type: "SET_NO_SURVEY_ROUTER_RESPONSE",
        response
    }
}