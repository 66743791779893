const initialState = {
    response: ""
}

const quotaFail = (state = initialState, action) => {
    switch (action.type) {
        case "SET_UPDATE_PARTICIPANT_RESPONSE":
            return {
                ...state,
                participantResponse: action.response,
                surveyResponse: null
            }

        case "SET_NO_SURVEY_REQUIRE" :
            return {
                ...state,
                surveyResponse: action.response,
                participantResponse: null
            }
        case "SET_UI_CONTENT" :
            return {
                ...state,
                uiContent: action.uiContent
            }
        case "SET_VENDOR_REDIRECT_UPDATE" :
            return {
                ...state,
                vendorRedirect: action.vendorRedirect
            }
        case "SET_TRACKING_RESPONSE" :
            return {
                ...state,
                trackingResponse: action.response
            }
        case "SET_NO_SURVEY_ROUTER_RESPONSE" :
            return {
                ...state,
                noSurveyResponse: action.response
            }
        default:
            return state;
    }
};
export default quotaFail;