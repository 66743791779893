import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import QuotaFail from './ui/index'
import { useClearCache } from "react-clear-cache";

function clickToUpdate(el){
  el.click()
}

function App() {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  return (
    <div>
    {isLatestVersion ? <div className="App">
        <header className="App-header">
          <Router>
            <div>
              <Route component={QuotaFail} exact path="/:key?" />
            </div>
          </Router>
        </header>
      </div> 
      : 
      <a
      href="."
      ref={clickToUpdate}
      onClick={e => {
        e.preventDefault()
        emptyCacheStorage()
      }}>
    </a>
}
</div>

  );
}

export default App;
