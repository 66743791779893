import { getUrlParam } from '../utils/utils';

export const requestData = (window) => {
  let queryString = window.location.search;
  let allQueryStrings = window.location.search;
  let queryOrigion = window.location.origin;
  let queryPathname = window.location.pathname;
  let origionNotHttp = queryOrigion.replace(/(^\w+:|^)\/\//, '');
  let origionNotHttp1 = origionNotHttp + queryPathname + allQueryStrings;
  let searchParams = "";
  
  if (queryString !== "") {
    searchParams = getUrlParam('UID', 'Empty');
    if(searchParams === "xxxx"){
      searchParams = getUrlParam("pid", "Empty");
    } else if (searchParams === 'Empty') {
      searchParams = getUrlParam('uid', 'Empty');
    }
  }

  return {
    searchParams : searchParams,
    origionNotHttp1 : origionNotHttp1,
    trackUrl : window.location.href
  }
}