import appConfig from "../../config/apiConfig";
import React from "react";
import { FormattedMessage } from 'react-intl';

export const getUrlParam = (parameter, defaultValue) => {
    let urlParameter = defaultValue;
    if (window.location.href.indexOf(parameter) > -1) {
        urlParameter = getUrlVars()[parameter];
    }
    return urlParameter;
}

function getUrlVars() {
    let vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
        vars[key] = value;
    });
    return vars;
}

function getParticipantUrl(participant) {
    let participantUrl = "";
    if (participant !== "") {
        if (participant.success && participant.newSurveyParticipant && participant.participantUrl) {
            participantUrl = appConfig.participantUrl + participant.participantUrl;
            participantUrl = participantUrl + "&isParticipantRouter=1&originParticipant=" + participant.participant_id;
        }
    }
    return participantUrl;
}

export const getRouterYesNoContent = (props, onYesClickedForNewSurvey, onNoSurveryRequired) => {
    return <main>
    <div className="round">
        <div className="round-circle"></div>
        <div className="round-caption">
            {/* <h2 className="modal-title "><FormattedMessage id="app.msg.congrats"/></h2> */}
            <p><FormattedMessage id="app.mgs.contentRouterChain"/></p>
            <button className="btn btn-primary" style= {{ marginRight : "16px", width : "70px", height : "40px"}} onClick = {onYesClickedForNewSurvey}>Yes</button>
            <button className="btn btn-primary" onClick = {onNoSurveryRequired} >No</button>
        </div>
    </div>
    <div className="right-image">
        <img src={require("../assets/images/student-experience.png")} alt="study" class="" />
    </div>
</main>
}


export const getDefaultContent = (header, message) => {
    return <main>
        <div className="round"> 
            <p style={{fontsize : "142%"}}>
                <FormattedMessage id="app.msg.redirected" />
              </p>
            <div className="round-circle"></div>
            <div className="round-caption">
                <h2 className="modal-title "><FormattedMessage id="app.msg.name" /></h2>
                <p><FormattedMessage id="app.msg.content" /></p>
            </div>
        </div>
        <div className="right-image">
            <img src={require("../assets/images/student-experience.png")} alt="study" className="" />
        </div>
    </main>
}

export const getWaitingContent = () => {
    return <main>
    <div className="round">
        <div className="round-circle"></div>
        <div className="round-caption">
            <h2 className="modal-title "><FormattedMessage id="app.msg.pleaseWait" /></h2>
            
        </div>
    </div>
    <div className="right-image">
        <img src={require("../assets/images/student-experience.png")} alt="study" className="" />
    </div>
</main>
}

export const getLeftContent = (participant) => {
    return <div id="leftcontainer" className="subcontainers">
        <div id="heading">
            <h2><FormattedMessage id="app.msg.welcome" /></h2>
            <h4><FormattedMessage id="app.msg.refresh" /></h4><br /><br />
            <div style={{ textAlign: "left", width: '80%', margin: '10%' }}>
                <span><b> Project Name &nbsp;&nbsp; : &nbsp;&nbsp; {participant.projectName}</b></span><br /><br />
                <span><b>Description &nbsp;&nbsp; : &nbsp;&nbsp; {participant.projectDescription}</b></span>
            </div>
        </div>
    </div>
}